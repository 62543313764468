@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GopherDisplay-Medium";
  src: local("GopherDisplay-Medium"),
    url(./fonts/GopherDisplay-Medium.otf) format("opentype");
}

@font-face {
  font-family: "TTC";
  src: local("TTC"), url(./fonts/TTC.otf) format("opentype");
}

@font-face {
  font-family: "TTC-DemiBold";
  src: local("TTC-DemiBold"), url(./fonts/TTC-DemiBold.otf) format("opentype");
}

/* create react app defaults */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* reset */
html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body {
  background-color: #111111;
  color: white;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2 {
  font-family: "GopherDisplay-Medium", sans-serif;
}

h3,
h4,
h5 {
  font-family: "GopherDisplay-Medium", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}

p,
span {
  font-family: "TTC", sans-serif;
}

.fadeIn {
  position: absolute;
  width: 100%;
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  position: absolute;
  width: 100%;
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
